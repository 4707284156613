.application-progress {
  height: .25rem;
  background: $progress-bg;
  width: 100%;
  position: fixed;
  z-index: $zindex-dropdown - 10;

  &__bar {
    transition: $transition-base;
    background: $progress-bar-bg;
    width: 0;
    height: 100%;
  }
}

.application-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden auto;
}

.application-form-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: map-get($container-max-widths, sm) !important;
}

.application {
  &__header {
    margin-bottom: 1.5rem;
  }

  &__header-graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background: $white;
    margin-bottom: 1.5rem;

    img {
      width: 5.5rem;
    }
  }

  &__agreement-container {
    display: flex;
    flex-flow: column;
    height: 29rem;
    padding: 1.5rem !important; // margin and padding to create white backround for terms
    background: $white;
    border-radius: $border-radius;
    text-align: left;
    overflow: hidden auto;
  }

  &__centered-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__content {
    margin: 0 -1rem;
    padding: 0 1rem;
    background: $gray-300;
    border-radius: $border-radius;
  }

  &__plaid {
    margin: 2rem 0 .75rem;

    svg {
      height: 1.5rem;
    }
  }

  &__sub-text {
    width: 70%;
    text-align: center;
    color: $link-color;
    font-size: $sub-sup-font-size;
  }

  &__info-block {
    display: flex;

    small {
      margin: 0;
      color: $text-muted;
    }
  }

  &__info-icon {
    font-size: 1rem;
    margin-top: .25rem;
    margin-right: .5rem;
    color: $info;
  }
}

.application__centered-content {
  .application__header {
    text-align: center;
    width: 90%;
  }

  .application__content, .application-form {
    width: 90%;
  }

  .application__button {
    width: 90%;
    margin: auto;
  }
}

.application__number-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: $input-bg;
  color: $input-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius * 3;
  height: $input-height;
  box-shadow: $input-box-shadow;
  padding: 0 .25rem;
  width: 60%;

  .btn {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    padding: 0;
  }
}

.application-form {
  display: flex;
  flex-flow: column nowrap;

  &__control-tooltip {
    position: absolute;
    top: .75rem;
    right: .75rem;
    font-size: .75rem;
    margin-top: 0;
    margin-right: -1px;
    display: flex;
    align-items: center;
    z-index: $zindex-sticky;
    border-radius: $tooltip-border-radius !important;
    background: $gray-200;

    img {
      height: 1.125rem;
      margin-right: .5rem;
    }
  }
}

.owner-details-form {
  background: $white;
  border-radius: $border-radius;
}

@include media-breakpoint-down(sm) {
  .application-form-container {
    padding-bottom: 7rem;
  }

  .application__centered-content {
    .application__header, .application__content,
    .application-form, .application__button {
      width: 100%;
    }
  }

  .application__header, .application__content,
  .application-form {
    br {
      display: none;
    }
  }
}
