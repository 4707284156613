.portal-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden auto;
}

.portal-header {
  background: $yellow;
  padding-left: 4rem;
  padding-right: 4rem;
  min-height: 352px;
  display: flex;
  align-items: center;
  &__content {
    padding-top: 4rem;
    padding-bottom: 4rem;
    gap: 48px;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .flex-column;
    @extend .flex-lg-row;
    max-width: 1120px !important;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: top 16px left, center center, bottom 16px right;
    @include media-breakpoint-down(lg) {
      padding-top: 5.5rem;
      padding-bottom: 1.25rem;
      background-size: 64px;
      gap: 0;
    }
    @include media-breakpoint-down(sm) {
      background-position: top 16px left, top 25% center, top 50% right;
    }
  }

  &__heading {
    @extend .position-relative;
    h1 {
      font-size: 2rem;
      line-height: 2.675rem;
      font-weight: 600;
      margin-bottom: 0;
      span {
        font-weight: 400;
        // @extend .d-block;
      }
      @include media-breakpoint-down(lg) {
        text-align: center;
        font-size: 1.375rem;
        line-height: 1.7325rem;
        max-width: 386px;
        margin-bottom: 80px;
      }
      @include media-breakpoint-down(lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__card {
    @include media_breakpoint_up(sm) {
      max-width: 345px;
    }
    width: 100%;
    background: $white;
    border-radius: 0.625rem;
  }

  &__credit-content {
    padding: 1.5rem;
    font-size: 15px;
    line-height: 22px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

// .modal-content {
//   width: auto !important;
// }

.date-picker-container {
  .rdrDefinedRangesWrapper {
    width: 120px;
  }
  .rdrInputRanges {
    display: none;
  }

  @include media_breakpoint_down(md) {
    max-height: calc(100vh - 300px);
    overflow-x: scroll;
    .rdrDefinedRangesWrapper {
      width: 100%;
      border-right: 0;
    }
  }
}

.font-22 {
  font-size: 22px;
  line-height: 32px;
}

.spring-top {
  width: 63.504px;
  height: 64.529px;
  position: absolute;
  left: -63.504px;
  top: -64.529px;
  @include media-breakpoint-down(lg) {
    left: 0;
    top: -60px;
    width: 50.872px;
    height: 51.692px;
  }
}
.spring-end {
  width: 85.306px;
  height: 86.682px;
  position: absolute;
  right: 0;
  bottom: -60px;
  @include media-breakpoint-down(lg) {
    right: -8px;
    bottom: 39px;
    width: 50.987px;
    height: 51.809px;
  }
}

.img-95 {
  width: 95px;
  height: 95px;
  border-radius: 50%;
}
.font-600 {
  font-weight: 600;
}
.font-400 {
  font-weight: 400;
}
.portal-content {
  max-width: 1160px !important;
  margin: 48px auto;
  padding-left: 16px;
  padding-right: 16px;
  .portal-content__row {
    @extend .d-md-flex;
    gap: 48px;
    @include media_breakpoint_down(lg) {
      gap: 32px;
    }
  }
  &__card {
    width: 100%;
    @include media_breakpoint_up(sm) {
      max-width: 364px;
    }
  }
  &__card-item {
    border-radius: 20px;
    padding: 32px 30px 32px 32px;
    margin-bottom: 24px;
    @include media_breakpoint_down(sm) {
      padding: 24px;
      margin-bottom: 16px;
    }
  }
  &__card-payback {
    background: #a79af2;
  }
  &__card-funding {
    background: #84eaa1;
    .list-group-item {
      display: flex;
      justify-content: space-between;
      border-color: $dark;
      background-color: transparent;
      padding: 12px 0;
      font-size: 14px;
      span {
        @extend .flex-shrink-0;
      }
    }
  }
  &__history {
    width: 100%;
    @include media_breakpoint_up(sm) {
      max-width: 362px;
    }
  }
  &__filter {
    font-size: 2.1875rem;
    line-height: 2.8125rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }
  .ant-select {
    width: 25%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  @include media_breakpoint_down(sm) {
    margin: 32px auto 48px;
    &__card-payback {
      display: flex;
      img {
        margin-right: 1rem;
      }
    }
  }

  .snapshot-card {
    padding: 2rem;
    border-radius: 1.25rem;

    img {
      max-width: 3rem;

      @include media-breakpoint-down(lg) {
        max-width: 2rem;
        margin-right: 0.75rem;
      }
    }

    &__title {
      font-size: 1.5rem;
      line-height: 1.875rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    &__amount {
      margin-top: 1.5rem;
      margin-bottom: 0;
      font-size: 4.6875rem;
      line-height: 4rem;
      font-weight: 700;

      @include media-breakpoint-down(lg) {
        font-size: 3.125rem;
        line-height: 3rem;
        text-align: center;
      }

      &.coming-soon {
        font-size: 3rem;

        @include media-breakpoint-down(lg) {
          font-size: 2rem;
        }
      }
    }
  }
}

.vertical-steps {
  list-style: none;
  padding-left: 0;
  position: relative;
  &::before {
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 12px;
    top: 0;
    background-color: $gray-700;
  }

  &__item {
    padding-left: 40px;
    padding-bottom: 16px;
    position: relative;
    &:nth-last-of-type(-n + 1) {
      &:before {
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        width: 1px;
        height: 100%;
        position: absolute;
        left: 12px;
        top: 0;
        background-color: $white;
      }
    }
    &:after {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      border: 1px solid $gray-700;
      position: absolute;
      left: 0;
      top: -4px;
      border-radius: 50%;
      background-color: $white;
    }
  }
  .item-date {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .item-content {
    background: #f5f7f8;
    border-radius: 100px;
    padding: 16px 24px;
  }
  .status-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    span {
      @extend .flex-shrink-0;
      font-size: 12px;
      font-weight: 400;
    }
  }
  &__item-btn {
    padding-bottom: 0;
    &:after {
      content: none;
    }
    &:before {
      content: none !important;
    }
    @include media_breakpoint_down(sm) {
      padding-left: 0;
    }
  }
  .btn {
    border-width: 1px;
    font-weight: 400;
  }
  &__item-success {
    &:after {
      background-color: #84eaa1;
      border-color: #84eaa1;
      background-image: url('data:image/svg+xml, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.9579 5.87828L7.36505 11.6778C6.9509 12.1074 6.27907 12.1074 5.86453 11.6778L2.97748 8.68363C2.56317 8.25405 2.56317 7.55727 2.97748 7.12761C3.39187 6.69788 4.06364 6.69788 4.47785 7.12745L6.61501 9.34379L11.4573 4.32218C11.8716 3.89244 12.5435 3.89277 12.9577 4.32218C13.3719 4.75184 13.3719 5.44838 12.9579 5.87828Z" fill="rgb(8, 51, 66)"/></svg>');
    }
    .item-content {
      background-color: rgba($color: #84eaa1, $alpha: 0.2);
    }
  }
  &__item-danger {
    &:after {
      background-color: $red;
      border-color: $red;
      background-image: url('data:image/svg+xml, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="rgb(241, 53, 53)"/><path d="M7.27934 7.27936C7.65179 6.90691 8.25565 6.90691 8.6281 7.27936L16.7206 15.3719C17.0931 15.7444 17.0931 16.3482 16.7206 16.7207C16.3482 17.0931 15.7443 17.0931 15.3719 16.7207L7.27934 8.62811C6.90689 8.25567 6.90689 7.65181 7.27934 7.27936Z" fill="white"/><path d="M7.27935 16.7206C6.9069 16.3482 6.9069 15.7443 7.27935 15.3719L15.3719 7.27934C15.7444 6.90689 16.3482 6.90689 16.7207 7.27934C17.0931 7.65179 17.0931 8.25565 16.7207 8.62809L8.62811 16.7206C8.25566 17.0931 7.6518 17.0931 7.27935 16.7206Z" fill="rgb(255,255,255)"/></svg>');
    }
    .item-content {
      border-radius: 27px;
      background-color: rgba($color: $danger, $alpha: 0.2);
      img {
        border-radius: 10px;
        margin-top: 12px;
      }
    }
  }
}

.footer {
  padding: 30px 24px;
  background-color: #083342;
  border-radius: 24px 24px 0 0;
  display: flex;
  justify-content: space-between;

  color: $white;
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: #84eaa1;
    }
  }
  p {
    margin-bottom: 0;
  }

  @include media_breakpoint_down(lg) {
    padding: 24px;
    flex-direction: column;
    span {
      margin-bottom: 8px;
    }
    .footer-logo {
      margin-bottom: 1.5rem;
    }
    .footer-text {
      margin-bottom: 8px;
    }
  }
}

.bg-yellow {
  background-color: #fff4bb;
}

.bg-violet {
  background-color: #a79af2;
}

.bg-green {
  background-color: #84eaa1;
}

.rdrCalendarWrapper {
  max-width: 100%;
  width: calc(100% - 120px);

  @include media_breakpoint_down(md) {
    width: 100%;

    .rdrMonth {
      width: 100%;
    }
  }
}

.rdrDateRangePickerWrapper {
  width: 100%;
  @include media_breakpoint_down(md) {
    display: block;
  }
}

.rdrStaticRanges {
  @include media_breakpoint_down(md) {
    flex-direction: unset;
    flex-wrap: wrap;
  }
}

.rdrInfiniteMonths {
  &.rdrMonthsHorizontal {
    width: 100% !important;
  }
}

.date-range-selection-container {
  position: relative;

  .form-control {
    min-width: 250px;
    max-width: 250px;
    padding-right: 45px;
  }

  .btn-date {
    position: absolute;
    top: 50%;
    right: 10px;
    background: transparent;
    padding: 0;
    border: 0;
    transform: translate(0, -50%);

    img {
      width: 30px;
      height: 30px;
    }
  }

  @include media_breakpoint_down(md) {
    width: 100%;

    .form-control {
      min-width: 100%;
      max-width: 100%;
    }
  }
  @include media_breakpoint_down(lg) {
    width: 100%;

    .form-control {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
