.app-navbar {
  justify-content: space-between;
}

.app-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  // min-height: -webkit-fill-available;
  width: 100%;
  padding: $navbar-height 0 0;
  overflow: hidden auto;
}

.toast-icon {
  font-size: 1.25rem;
  margin-right: .5rem;

  &__danger {
    color: $danger;
  }
}
