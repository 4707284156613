.approved-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden auto;
}

.approved-header {
  background: $yellow;
  padding-left: 4rem;
  padding-right: 4rem;
  min-height: 300px;
  display: flex;
  align-items: center;
  &__content {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    gap: 48px;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .flex-column;
    @extend .flex-lg-row;
    @extend .position-relative;
    max-width: 1120px !important;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: top 16px left, center center, bottom 16px right;
    @include media-breakpoint-down(lg) {
      padding-top: 4.75rem;
      padding-bottom: 1.25rem;
      background-size: 64px;
      gap: 0;
    }
    @include media-breakpoint-down(sm) {
      // background-position: top 16px left, top 25% center, top 50% right;
    }
  }

  &__heading {
    h1 {
      font-size: 4.375rem;
      line-height: 4.875rem;
      font-weight: 600;
      margin-bottom: 0;
      span {
        font-weight: 400;
        // @extend .d-block;
      }
      @include media-breakpoint-down(lg) {
        text-align: center;
        font-size: 2.875rem;
        line-height: normal;
        margin-bottom: 80px;
      }
      @include media-breakpoint-down(lg) {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__card {
    @include media_breakpoint_up(sm) {
      max-width: 345px;
    }
    width: 100%;
    background: $white;
    border-radius: 0.625rem;
  }

  &__credit-content {
    padding: 1.5rem;
    font-size: 15px;
    line-height: 22px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.approved-content {
  @extend .position-relative;
  max-width: 1120px !important;
  width: 100%;
  padding: 80px 16px 140px;
  padding-bottom: 80px;
  margin: 0 auto;
  text-align: center;
  &-text {
    max-width: 485px;
  }
  .font-22 {
    font-size: 22px;
    line-height: 32px;
  }

  .spring-top {
    width: 70.552px;
    height: 71.69px;
    position: absolute;
    left: 90px;
    top: 75px;
    @include media-breakpoint-down(lg) {
      left: 10px;
      top: 25px;
      width: 50.872px;
      height: 51.692px;
    }
  }
  .spring-end {
    width: 70.67px;
    height: 71.81px;
    position: absolute;
    right: 110px;
    bottom: 40px;
    @include media-breakpoint-down(lg) {
      right: 0;
      bottom: 50px;
      width: 50.987px;
      height: 51.809px;
    }
  }

  .img-95 {
    width: 95px;
    height: 95px;
    border-radius: 50%;
  }
  .font-600 {
    font-weight: 600;
  }
  .font-400 {
    font-weight: 400;
  }
  .approved-heading {
    @include media_breakpoint_up(md) {
      font-size: 40px;
      font-weight: 600;
    }
  }

  .mw-283 {
    max-width: 283px;
  }

  .icon-rock {
    position: absolute;
    top: -37px;
    left: 0;
  }

  .icon-cone {
    position: absolute;
    bottom: 35px;
    right: 0;
    @include media_breakpoint_down(sm) {
      bottom: 0;
    }
  }
}
