.nav-dropdown-toggle {
  display: flex;
  align-items: center;
  color: $gray-900;
  transition: $transition-base;

  &__profile {
    margin-left: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: $purple;
    color: $white;
    font-size: .85rem;
    transition: $transition-base;
  }

  &:hover, &:focus, &:active {
    color: shade-color($gray-900, 40%);

    .nav-dropdown-toggle__profile {
      background: shade-color($purple, 10%);
      color: shade-color($white, 20%);
    }
  }
}
