.input-pwd {
  position: relative;
  padding-right: 50px !important;
}
.btn-pwd {
  position: absolute;
  right: 10px;
  bottom: 12px;
  border: none;
  background-color: transparent;
  @include media_breakpoint_down(md) {
    right: 5px;
  }
}

.app-login {
  width: 100%;
  padding: 0 1rem;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #f8f6f4;
  .container {
    max-width: 450px;
    border-radius: 1rem;
    box-shadow: 0 0.75rem 2.5rem rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 2.5rem;
  }
  .header {
    padding-bottom: 1.5rem;
    line-height: 1.5;
    text-align: center;

    .title {
      font-size: 1.5rem;
      margin: 0.5rem 0;
      font-weight: 400;
    }

    .content {
      font-size: 0.875rem;
      margin: 0;
    }
  }
}

.app-login {
  .form-control.is-invalid {
    background-image: none;
  }
}
