.support-body {

  &__content {
    padding: 2rem;
    text-align: center;
  }

  &__p {
    font-size: $small-font-size;
    width: 90%;
    text-align: center;
    margin: 0 auto;
  }
}
