$approved-body-header-height: 18rem;

.credit-calculation-body {
  display: flex;
  flex-flow: column nowrap;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    padding: 0 2rem;
    flex: 1 1 auto;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__action-btn {
    padding: 1.5rem 2rem 2rem;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
  }
}

.calculating-body {
  &__content {
    min-height: 40rem;
    flex: 0 0 100%;
    justify-content: center;
  }

  &__connection {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    top: 2rem;
    left: 2rem;
    position: absolute;
    border: 2px dashed $gray-700;
    z-index: 0;
  }
}

.credit-calculation-circle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 20rem;
  height: 20rem;
  position: relative;

  &__vendor {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    background: $gray-500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .75rem;
    z-index: 1;
  }
}

.approved-body {
  &__header {
    flex: 0 0 $approved-body-header-height;
    flex-flow: column nowrap;
    height: $approved-body-header-height;
    text-align: center;
    justify-content: flex-end;
    padding: 0 2rem 1.5rem;
    position: relative;

    h2, p, small {
      z-index: 1;
    }
  }

  &__credit-graphic {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    top: 0;
    bottom: 35%;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__credit-checkmark {
    color: $green;
    font-size: 2rem;
    position: absolute;
    right: .35rem;
    top: 1.75rem;
  }

  &__credit-detail {
    width: 100%;
    text-align: start;
    background: $gray-300;
    border-radius: $border-radius;

    .list-group-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background: transparent;
    }

    h6 {
      margin: 0;
      flex: 1 1 auto;
      font-weight: normal;
    }
  }
}

.declined-body {
  &__content {
    padding: 3.75rem 2rem 2rem;
  }

  &__emoji {
    width: 4.5rem;
    height: 4.5rem;
    margin-bottom: 1.5rem;
  }

  &__p, &__ul {
    font-size: $small-font-size;
    width: 90%;
  }

  &__ul {
    text-align: left;
  }
}

@include media-breakpoint-down(sm) {
  .credit-calculation-body__header,
  .credit-calculation-body__content {
    br {
      display: none;
    }
  }

  .approved-body__credit-detail {
    width: 100%;
  }
}
