.cash-advance-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden auto;
}

.cash-advance-form-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: map-get($container-max-widths, sm) !important;
}

.cash-advance {
  &__header-graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: $green;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: $white;
  }

  &__header {
    margin-bottom: 1.5rem;
  }

  &__centered-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.cash-advance__centered-content {
  .cash-advance__header {
    text-align: center;
    width: 90%;
  }

  .cash-advance__content, .cash-advance-form {
    width: 90%;
  }

  .cash-advance__button {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.cash-advance-form {
  display: flex;
  flex-flow: column nowrap;

  .form-label {
    font-weight: 500;
  }

  &__control-tooltip {
    position: absolute;
    top: .75rem;
    right: .75rem;
    font-size: .75rem;
    margin-top: -1px;
    display: flex;
    align-items: center;
    z-index: $zindex-sticky;
    border-radius: $tooltip-border-radius !important;
    background: $gray-200;

    img {
      height: 1.125rem;
      margin-right: .5rem;
    }
  }

  .is-invalid~.cash-advance-form__control-tooltip, .was-validated :invalid~.cash-advance-form__control-tooltip {
    display: none;
  }

  &__toggle-btn-group {
    .btn {
      font-size: 1rem;
      width: 14.5rem;
      margin-right: 2rem;
      margin-bottom: 1rem;
      background: $white;
      color: $black;
      font-weight: 500;

      &:hover, &:focus, &:active {
        background: rgba($primary, .1);
        color: $primary;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .btn-check:checked + .btn {
      background: $primary;
      color: $white;
      font-weight: 600;
    }
  }

  &__readonly-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: .75rem;
    padding-bottom: .75rem;

    h6 {
      margin-right: .75rem;
      margin-bottom: 0;
    }

    aside {
      text-align: right;
    }
  }
}

@include media-breakpoint-down(sm) {
  .cash-advance-form-container {
    padding-bottom: 7rem;
  }

  .cash-advance__centered-content {
    .cash-advance__header, .cash-advance__content,
    .cash-advance-form, .cash-advance__button {
      width: 100%;
    }
  }

  .cash-advance__header, .cash-advance__content,
  .cash-advance-form {
    br {
      display: none;
    }
  }
}
