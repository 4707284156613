.public-view-navbar {
  justify-content: center;
}

.public-view-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding-top: $navbar-height;
  background: $white;
  overflow: hidden auto;
}

.document-container {
  margin-top: 2rem;
}
