.bill-detail-container {
  flex: 1 1 auto;
  width: 100%;
  background: $yellow-100;
  padding: 0 1rem;
  overflow: hidden auto;
}

.bill-detail-banner {
  flex: 0 0 auto;
  width: 100%;
  background: $gray-200;
  color: $gray-700;
  font-size: .75rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: .75rem 0;

  p {
    margin: 0;
  }
}

.bill-detail-content {
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin: 0 auto;
  max-width: map-get($container-max-widths, sm) !important;
}
