.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: $zindex-toast;
  background: rgba($black, .7);

  &__title {
    margin-top: 1.5rem;
    color: $white;
  }
}
