.navbar {
  padding: 1rem;
  height: $navbar-height;
  align-items: center;
}

.form-floating {
  // TODO: Revisit
  // > label {
  //   opacity: 1 !important;
  // }

  > .form-select {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
  }

  // TODO: Not generic enough
  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-tooltip,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-tooltip {
    top: 0.8125rem;
    right: 2.75rem;
    font-size: 0.75rem;
    margin-top: 0;
    z-index: $zindex-sticky;
  }
}

// TODO: Not generic enough
.input-group {
  .form-control-lg.is-invalid ~ .invalid-tooltip,
  .form-control-lg.was-validated :invalid ~ .invalid-tooltip {
    top: 0.75rem;
  }

  .is-valid ~ .valid-tooltip,
  .was-validated :valid ~ .valid-tooltip,
  .is-invalid ~ .invalid-tooltip,
  .was-validated :invalid ~ .invalid-tooltip {
    top: 0.45rem;
    right: 2.25rem;
    font-size: 0.75rem;
    margin-top: -1px;
    z-index: $zindex-sticky;
  }
}

input[type='date'] {
  position: relative;
  text-indent: 0.75rem;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0.625rem;
    bottom: 0.25rem;
    cursor: pointer;
  }
}

.number-input-without-spin-btn {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.markdown-container {
  table {
    empty-cells: hide;

    thead > tr {
      border-width: 0;
    }

    > :not(caption) > * {
      border-width: $table-border-width 0;
      border-color: $gray-300;
      padding: 0.25rem 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .action-btn-container {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem 0 2rem;

    .btn {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .action-btn-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem 1rem 2rem;
    z-index: $zindex-fixed;

    .btn {
      width: 100%;
    }
  }
}

.both-action-btn-container {
  display: flex;
  justify-content: space-between;
}

.ant-select-single {
  height: 60px;
  .ant-select-selector {
    border-radius: 2rem;
    font-size: 1.125rem;
    border-color: #083342 !important;
    padding: 0 1.5rem !important;
  }
  .ant-select-arrow {
    color: #083342;
    margin-right: 1rem;
  }
}
