.repayment {
  &__progress {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: $gray-300;
    padding: 1.25rem 1.25rem 1.5rem;
    margin-left: -1rem;
    margin-right: -1rem;
    font-weight: 500;
  }

  &__progress-title-container {
    width: 100%;
    max-width: map-get($container-max-widths, md);
  }

  &__progress-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }

  &__disbursement {
    margin: 1.5rem -1rem;

    .list-group-item {
      background: transparent;
    }
  }

  &__repayment-schedule {
    position: relative;
    margin-top: 1rem;
  }

  &__repayment-schedule-line {
    position: absolute;
    top: 1.375rem;
    bottom: 1.375rem;
    left: calc(1.375rem - 1px);
    width: 3px;
    background: $gray-300;
  }

  &__repayment-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__repayment-item-status {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    background: $gray-300;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__repayment-item-status_check {
    background: $green;
    color: $white;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__repayment-item-status_error {
    background: $red;
    color: $white;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__repayment-item-content {
    flex: 1 1 auto;
    margin-left: 1.5rem;

    h6 {
      margin-bottom: .25rem;
    }
  }

  &__repayment-item-date {
    // flex: 1 1 auto;
    color: $text-muted;
  }
}
