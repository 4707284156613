.landing-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 42rem !important;
  text-align: center;

  .get-started-btn {
    width: 22rem;
    margin-top: 3rem;
  }

  .carousel-indicators {
    bottom: -3rem;
  }

  .carousel-control-prev, .carousel-control-next  {
    display: none;
  }

  .carousel-item {
    padding: 0 1rem;
    overflow: hidden;
  }

  .landing-card {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-radius: $border-radius-lg;
    height: 22.5rem;
    overflow: hidden;
  
    &_purple {
      background: $purple;
    }

    &_teal {
      background: $teal;
    }
  
    > .carousel-caption {
      position: relative;
      left: 2rem;
      bottom: 0;
      color: $white;
      text-align: left;
      width: 50%;
      z-index: 2;

      h1 {
        color: $white;
        line-height: 1;
      }
    }
  
    > img {
      position: absolute;
      right: 2rem;
      z-index: 1;
    }

    > .graph-circle {
      position: absolute;
      width: 12.25rem;
      height: 12.25rem;
      top: -6.125rem;
      right: -6.125rem;
      border-radius: 50%;
      border: 3rem solid rgba($white, .5);
    }
  }
}

@include media-breakpoint-down(sm) {
  .landing-container {
    .get-started-btn {
      width: 100%;
    }

    .landing-card {
      flex-flow: column nowrap;
      height: auto;

      > .carousel-caption {
        order: 2;
        left: 0;
        width: 80%;
      }
    
      > img {
        order: 1;
        position: relative;
        right: 0;
      }
    }
  }
}
