.notification {
  position: fixed;
  width: 75%;
  top: 5rem;
  left: 12.5%;
  right: 12.5%;
  display: flex;
  flex-flow: column nowrap;
  z-index: $zindex-tooltip;

  .notification__alert {
    flex: 1 1 auto;
    padding-right: 4rem;
    color: $white;

    &.alert-primary {
      background: rgba($primary, .75);
      border-color: $primary;
    }

    &.alert-secondary {
      background: rgba($secondary, .75);
      border-color: $secondary;
    }

    &.alert-success {
      background: rgba($success, .75);
      border-color: $success;
    }

    &.alert-danger {
      background: rgba($danger, .75);
      border-color: $danger;
    }

    &.alert-warning {
      background: rgba($warning, .75);
      border-color: $warning;
    }

    &.alert-info {
      background: rgba($info, .75);
      border-color: $info;
    }

    &.alert-light {
      background: rgba($light, .75);
      border-color: $light;
    }

    &.alert-dark {
      background: rgba($dark, .75);
      border-color: $dark;
    }
  }

  .close {
    position: absolute;
    right: 1.25rem;
    display: flex;
    color: rgba($white, .75);
    transition: $transition-base;

    &:hover, &:focus, &:active {
      color: $white;
    }
  }
}
