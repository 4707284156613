.dashboard-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden auto;
}

.dashboard-header {
  background: $teal;
  background-repeat: no-repeat;
  background-position: 65% 0;
  padding: 2rem 1rem 2.25rem;
  background-size: contain;

  &__content {
    max-width: map-get($container-max-widths, md) !important;
    margin: 0 auto;
  }

  &__credit {
    background: $white;
    border-radius: .75rem;
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
    overflow: hidden;
  }

  &__credit-graphic {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: $border-radius;
    background: #C7FFD7;

    img {
      width: 3rem;
      height: 3rem;
    }
  }

  &__credit-content {
    flex: 1 1 auto;
    display: flex;
    margin: -1rem -.75rem -1rem 0;
    padding: .5rem 0;
    gap: 1rem;
    overflow: auto hidden;
  }

  &__credit-content-block {
    background-color: $gray-100;
    padding: .75rem 1.25rem;
    border-radius: $border-radius;

    > * {
      margin: 0;
    }

    small {
      font-size: 75%;
      color: $gray-900;
    }

    p {
      color: $gray-900;
      margin-top: -.25rem;
      text-align: center;
    }
  }
}

.bills-and-repayments {
  padding: 0 1rem 3rem;

  &__content {
    max-width: map-get($container-max-widths, md) !important;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-flow: column nowrap;

    > * {
      width: 100%;
    }
  }

  &__payment-zone {
    border-radius: $border-radius;
    border: 2px dashed $gray-700;
    min-height: 16rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  &__card {
    margin-top: 1.5rem;
    color: $body-color;
    text-decoration: none;
    position: relative;
    transition: $transition-base;

    .card-body {
      padding: 1.5rem;
    }

    .card-title {
      margin-bottom: .75rem;
    }

    .card-text {
      color: $text-muted;
      margin-bottom: .25rem;
    }

    &:hover, &:active, &:focus {
      background: rgba($gray-100, .35);
    }
  }

  &__card-footer {
    display: flex;
    justify-content: space-between;
    padding: .5rem 1.5rem;
    background: $gray-200;
    color: $text-muted;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top: 1px solid $card-border-color;
  }

  &__card-caret {
    font-size: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 35%;
  }
}

.peach-portal {
  width: 100%;
  height: 100vh;
}
