.how-did-container {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden auto;
  margin-top: 32px;
}

.how-did-content {
  @extend .position-relative;
  max-width: 480px !important;
  width: 100%;
  padding: 0 16px;
  padding-bottom: 80px;
  margin: 0 auto;
}

.error-msg {
  display: block !important;
}
